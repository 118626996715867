<template>
  <div>
    <router-view />
  </div>
</template>
<script>
import "overlayscrollbars/css/OverlayScrollbars.min.css";
import "@/assets/adminlte/css/adminlte.css";
import "@/assets/fontawesome/css/all.css";
//import "@/assets/font/font.css";
import "@/assets/custom.css";

import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "overlayscrollbars/js/jquery.overlayScrollbars.min.js";
import "@/assets/adminlte/js/adminlte.js";
import "@/assets/custom.js";
export default {
  name: "BaseView"
};
</script>