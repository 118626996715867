<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark"><i class="nav-icon fas fa-tachometer-alt"></i> Dashboard</h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">
                  <i class="nav-icon fas fa-home"></i> Home
                </router-link>
              </li>
              <li class="breadcrumb-item active"><i class="nav-icon fas fa-tachometer-alt"></i> Dashboard</li>
            </ol>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <!-- Small boxes (Stat box) -->
        <div class="row">
          <div class="col-md-6" v-for="relatorio in relatorios" :key="relatorio.titulo">
            <div class="card">
              <div class="card-header" >
                <h3 class="card-title">{{relatorio.titulo}}</h3>
                <div class="card-tools">
                  <button type="button" class="btn btn-tool" data-card-widget="collapse">
                    <i class="fas fa-minus"></i>
                  </button>
                  <button type="button" class="btn btn-tool" data-card-widget="remove">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
              <div class="card-body">
                <div class="chart">
                  <Pie :chartData="relatorio.chartData" :chartOptions="chartOptions" />
                </div>
              </div>
              <div class="card-footer p-0">
                <ul class="nav nav-pills flex-column">
                  <li class="nav-item">
                    <a href="#" class="nav-link"> Atendimentos <span class="float-right text-primary">{{relatorio.atendimentos}}</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="#" class="nav-link"> Resolvidos <span class="float-right text-success">{{relatorio.resolvidos}}</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="#" class="nav-link"> Pendentes <span class="float-right text-warning">{{relatorio.pendentes}}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
import { Pie } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from "chart.js";
import ContatoController from "@/controllers/contato.controller";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement);

export default{
  name: "Dashboard",
  components: { Pie },
  data() {
    return {
      relatorios: [],
      chartOptions: {
        maintainAspectRatio : false,
        responsive : true
      }
    }
  },
  mounted(){
    this.loadData();
  },
  methods: {
    loadData: function() {
      ContatoController.getRelatorio()
      .then(response => {
        this.relatorios = response.data.result;
      });
    }
  }
}
</script>

<style scoped>
.col-md-6 {
  padding-right: 7.5px !important;
  padding-left: 7.5px !important;
}

.row {
  margin: 0px 0px 0px 0px;
}
</style>