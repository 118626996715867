import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/Login';
import Main from '@/views/Main';
import Dashboard from '@/views/Dashboard';
import Chat from '@/views/Chat';
import BaseView from '@/components/main/BaseView';
import BaseChatView from '@/components/main/BaseChatView';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    component: Login
  },
  {
    path: '/chat',
    component: BaseChatView,
    children: [
      {
        path: '/',
        component: Chat,
        meta: { requiresAuth: true },
        children: [
          {
            path: ':id_contato',
            name: 'chat'
          }
        ]
      }
    ],
    beforeEnter(to, from, next) {
      if (store.state.token) {
        next();
      } else {
        next("/login");
      }
    }
  },
  {
    path: "/",
    component: Main,
    meta: { requiresAuth: true },
    children: [
      { 
        path: '/dashboard', 
        component: Dashboard,
        meta: { requiresAuth: true }
      },
      { 
        path: '/usuario',
        component: BaseView,
        children: [
          {
            path: '/',
            name: 'list-usuario',
            component: () => import("@/views/usuario/List.vue"),
            meta: { requiresAuth: true }
          },
          {
              path: ':id',
              name: 'form-usuario',
              component: () => import("@/views/usuario/Form.vue"),
              meta: { requiresAuth: true }
          }
        ]
      }
    ],
    beforeEnter(to, from, next) {
      if (store.state.token) {
        next();
      } else {
        next("/login");
      }
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: "active",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    $(".preloader").children().show();
    $(".preloader").css('height', '100%');
  
    setTimeout(function () {  
      $(".preloader").css('height', 0);      
      setTimeout(function () {
        $(".preloader").children().hide();
      }, 200);
    }, 500);
  
    if (store.state.token) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router;