import 'expose-loader?$!expose-loader?jQuery!jquery';
import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';

Vue.prototype.$baseApi = process.env.VUE_APP_BASE_API;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');