<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <router-link to="/" class="brand-link">
      <img
        src="@/assets/adminlte/img/AdminLTELogo.png"
        alt="AdminLTE Logo"
        class="brand-image img-circle elevation-3"
        style="opacity: .8"
      />
      <span class="brand-text font-weight-light">AdminLTE 3</span>
    </router-link>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column nav-compact nav-child-indent" data-widget="treeview" role="menu" data-accordion="false">
          <li class="nav-header">MENU</li>
          <li class="nav-item">
            <router-link to="/dashboard" class="nav-link">
              <i class="nav-icon fas fa-tachometer-alt"></i>
              <p>
                Dashboard
              </p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/chat" class="nav-link">
              <i class="nav-icon fab fa-whatsapp"></i>
              <p>
                WhatsApp
              </p>
            </router-link>
          </li>
          <li class="nav-header">ADMINISTRAÇÃO</li>
          <li class="nav-item">
            <router-link to="/usuario" class="nav-link">
              <i class="nav-icon fas fa-users"></i>
              <p>
                Usuários
              </p>
            </router-link>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
export default {
  name: "SidebarMenu",
  data: function() {
    return {

    };
  },
  computed: {

  },
  methods: {

  }
};
</script>

<style>
</style>