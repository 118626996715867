<template>
  <div>
    <router-view />
  </div>
</template>
<script>
import "@/assets/chat.css";

export default {
  name: "BaseChatView"
};
</script>
<style lang="scss" scoped>

</style>