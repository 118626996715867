import http from '@/utils/http';

export default {
  async sendMessage(data) {
    return await http.post('/api/mensagem/send', data);
  },
  async getMessages(data) {
    return await http.post('/api/mensagem/get', data);
  },
  async clearMessages(data) {
    return await http.post('/api/mensagem/clear', data);
  },
  async setWebhook() {
    return await http.post('/api/mensagem/webhook');
  },
  async readMessage(data) {
    return await http.post('/api/mensagem/read', data);
  },
  async sendFile(data) {
    return await http.post('/api/mensagem/media', data);
  },
};